import axios from 'axios';
import Service from '../Service/Service';
import {Patient} from "../../types/Patient";
import usePatientData from "../../components/PatientDetails/PatientHook";
import {localStorageRefs} from "../../Refs";

export default class PatientDetailsService extends Service {

  private casanaCloudApiUrl: string;
  public patient = usePatientData();
  public patientID : number;

  constructor(authContext: any, patientContext: any) {
    super(authContext);
    this.casanaCloudApiUrl = `${process.env.REACT_APP_API_URL}/v2/`;
    let localId = localStorage.getItem('casana-patient-id');
    let localInt = localId ? parseInt(localId) : 0;

    if (localInt !== 0) {
      patientContext.seat_user_id = localInt;
    }

    this.patientID = localInt;
  }

  /**
   * Connect to the Casana Cloud to retrieve the Seat User (Patient) information
   *
   * @param seatUserId
   * @param patientPin
   */
  public retrieveSeatUserDetails(seatUserId: number|undefined, patientPin?: string): any{

    let config = this.getRequestConfig();
    let url;

    if (patientPin) {
      url = `${this.casanaCloudApiUrl}setup_wizard/patient_search?pin=${patientPin}`;
    } else {
      url = `${this.casanaCloudApiUrl}setup_wizard/patient/${seatUserId}`;
    }

    return axios.get(url, config)
      .then((response) => {
        console.debug('PatientDetailsService.ts - retrieveSeatUserDetails()', response);
      let patient = {
        'seatUserId': 0,
        'email': '',
        'cellPhoneId': 0,
        'cellPhone': '',
        'firstName': '',
        'lastName': '',
        'dateOfBirth': '',
        'monitoredSince': '',
        'addressId': 0,
        'address': '',
        'addressOther': '',
        'city': '',
        'state': '',
        'zipCode': '',
        'country': '',
        'sexAssignedAtBirth': '',
        'seatUserCalibrations': '',
        'assignedSeatId': 0,
        'assignedSeatSerialNumber': '',
      };

      patient.seatUserId = response.data.seatUserId;
      patient.email = response.data.email;
      patient.cellPhoneId = response.data.cellPhone?.id;
      patient.cellPhone = response.data.cellPhone?.phoneNumber;
      patient.firstName = response.data.firstName;
      patient.lastName = response.data.lastName;
      patient.dateOfBirth = response.data.dateOfBirth;
      patient.monitoredSince = response.data.monitoredSince;
      patient.addressId = response.data.seatUserAddress?.id;
      patient.address = response.data.seatUserAddress?.address;
      patient.addressOther = response.data.seatUserAddress?.addressOther;
      patient.city = response.data.seatUserAddress?.city;
      patient.state = response.data.seatUserAddress?.state;
      patient.zipCode = response.data.seatUserAddress?.zipCode;
      patient.country = response.data.seatUserAddress?.country ?? 'US';
      patient.sexAssignedAtBirth = response.data.sexAssignedAtBirth;
      patient.seatUserCalibrations = response.data.seatUserCalibrations;
      patient.assignedSeatId = this.extractSeatIdFromAssignedSeats(response.data.seats);
      patient.assignedSeatSerialNumber = this.extractSerialNumberFromAssignedSeats(response.data.seats);

      this.patient.parsePatientData(patient);

      return {
          success: true,
          data: patient
        }
      }
    )
    .catch(function (error) {
      console.error('PatientDetailsService.ts -> retrieveSeatUserDetails()', error);
      return {
        success: false,
        data: {
          errors: error.statusText,
          status_code: error.status
        }
      }
    });
  }

  /**
   * Update the Seat User entity on the Casana Cloud via the API. ONly send address information if we have some of
   * it to send. the backend will tell us that we have to fill everything out if one piece of information is
   * missing.
   */
  public updateSeatUserDetails(patientDetails: Patient): any{
    let config = this.getRequestConfig();

    let seatUserId = patientDetails.seatUserId;
    let data: any = {
      "firstName": patientDetails.firstName,
      "lastName": patientDetails.lastName,
      "dateOfBirth" : patientDetails.dateOfBirth,
      "monitoredSince" : patientDetails.monitoredSince,
      "sexAssignedAtBirth" : patientDetails.sexAssignedAtBirth,
      "heightFeet" : patientDetails.seatUserCalibrations?.heightFeet
        ? patientDetails.seatUserCalibrations.heightFeet
        : 0,
      "heightInches" : patientDetails.seatUserCalibrations?.heightInches
        ? patientDetails.seatUserCalibrations.heightInches
        : 0,
      "sternalNotch" : patientDetails.seatUserCalibrations?.sternalNotch,
      "seatId" : Number(localStorage.getItem(localStorageRefs.seatId)),
      "phoneId": patientDetails.cellPhoneId,
      "phone": patientDetails.cellPhone,
      "email": patientDetails.email,
      "addressId": patientDetails.addressId,
      "address": patientDetails.address,
      "addressOther": patientDetails.addressOther,
      "city": patientDetails.city,
      "state": patientDetails.state,
      "zip": patientDetails.zipCode,
      "country": "US"
    }

    return axios.patch(`${this.casanaCloudApiUrl}setup_wizard/patient/${seatUserId}`, data, config).then(
      (response) => {
        return {
          success: true,
          data: {
            seatUserId: seatUserId,
            seatUser: response.data
          }
        }
      })
      .catch(function (error) {
        console.debug('PatientDetailsService.ts - updateSeatUserDetails() ERROR', error);
        return {
          success: false,
          data: {
            errors: error.response.data.detail
          }
        }
      });
  }

  /**
   * If none of the address parts are set by the user then we don't need to send the fields in the post
   * request. If we send empty values the backend will complain about them not being set.
   *
   * @param patientDetails
   *
   * @private
   */
  private determineIfPhoneIsNeeded(patientDetails: Patient){
    return patientDetails.cellPhone !== '';
  }

  /**
   * If none of the address parts are set by the user then we don't need to send the fields in the post
   * request. If we send empty values the backend will complain about them not being set.
   *
   * @param patientDetails
   *
   * @private
   */
  private determineIfAddressIsNeeded(patientDetails: Patient){
    return patientDetails.address !== '' ||
      patientDetails.addressOther !== '' ||
      patientDetails.city !== '' ||
      patientDetails.zipCode !== '' ||
      patientDetails.state !== '';
  }

  /**
   * This function should take in a value from the API response for assigned_seats
   * Sample: [{"1459":"F20AB7EC88FB"}] and return the seat_id for the first
   * value returned even if there is multiple assigned/connected seats.
   * @param assignedSeats
   * @return int
   */
  private extractSeatIdFromAssignedSeats(assignedSeats: any) {
    let seatId = '';
    //TODO: add some defensive programming to make sure if there are no assigned seats or multiple assigned seats
    //TODO: it still works
    if(assignedSeats.length > 0) {
      Object.keys(assignedSeats[0]).forEach(function(key) {
        seatId = key;
      });
      return parseInt(seatId);
    }
    return 0;
  }

  /**
   * This function should take in a value from the API response for assigned_seats
   * Sample: [{"1459":"F20AB7EC88FB"}] and return the serial number for the first
   * value returned even if there is multiple assigned/connected seats.
   * @param assignedSeats
   * @return string
   */
  private extractSerialNumberFromAssignedSeats(assignedSeats: any) {
    let seatSerialNumber = '';
    //TODO: add some defensive programming to make sure if there are no assigned seats or multiple assigned seats
    //TODO: it still works
    if(assignedSeats.length > 0) {
      Object.keys(assignedSeats[0]).forEach(function(key) {
        seatSerialNumber = assignedSeats[0][key];
      });
      return seatSerialNumber;
    }
    return '';
  }
}
