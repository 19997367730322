/**
 * Firmware versions at or after this version return wifi lists, scrub wifi passwords
 * (and seem to not update checkin time when checkin_with_upload is called).
 */
export const firmwareCutoffVersion = '0.24.0';

/**
 * Minimum sit time in seconds for a valid initialization.
 */
export const defaultSitTime = 270;

/**
 * Time to wait for seat to reconnect after BLE disconnection before displaying error modal (in milliseconds).
 */
export const seatBleReconnectTimeout = 10 * 1000;

/**
 * Seat Process events for event listeners
 */
export const seatProcessRecordingCode = 0x4552;
export const seatProcessCheckinCode = 0x4943;
export const seatProcessFwDownloadCode = 0x4446;
export const seatProcessFwCheckCode = 0x4346;
export const seatProcessDirCheckCode = 0x4344;

/**
 * Seat Process error codes for event listeners
 */
export const seatProcessErrorCodeDNSFailed = 1025; // Wifi endpoint connection error code for older firmware
export const seatProcessErrorCodeErrIO = 1000; // Wifi general connection error code for newer firmware
export const seatProcessErrorCodeErrInval = 1002; // Invalid configuration error code for newer firmware

/**
 * Local storage values
 */
export const localStorageRefs = {
  sessionExpiry: 'casana-session-expiry',
  installerId: 'casana-installer-id',
  seatId: 'casana-seat-id',
  patientId: 'casana-patient-id',
  sessionToken: 'casana-api-jwt'
}

/**
 * Login session Values
 */
export const sessionRefs = {
  expiryMs: 30 * 60 * 1000, // 30 minutes to match back end. If not refreshed, user will be logged out automatically.
  checkExpiryIntervalMs: 30 * 1000, // Check and update the session time left every 30 seconds.
  expiryCutoffMs: 3 * 60 * 1000 // Warn the user 3 minutes before session will end to refresh or logout.
}

export const initRefs = {
  systolicBpMin: 60,
  systolicBpMax: 299,
  diastolicBpMin: 40,
  diastolicBpMax: 299,
  weightLbsMin: 90,
  weightLbsMax: 350,
  sternalNotchInchesMin: 13,
  sternalNotchInchesMax: 30,
  heightFtMin: 0, // @todo revisit height constraints
  heightFtMax: 9,
  heightInMin: 0,
  heightInMax: 99,
  heightTotalCmMax: 275,
  heightTotalInMax: 108,
  heightTotalCmMin: 0, // @todo need minimum height constraint
  heightTotalInMin: 0,
}

export const validationRefs = {
  emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  passwordRegex: /(?=.*[A-Z])(?=.*[a-z])(?=.*\W)[A-Za-z\W0-9]{10,}$/,
  passwordMsg: 'Password must be at least 10 characters long and contain at least one special character, one uppercase letter, and one lowercase letter. ',
  emailMsg: 'Invalid email address. ',
  passwordMismatchMsg: 'Passwords do not match. ',
  emojiRegex: /([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g,
  emojiMsg: 'Emojis are not permitted',
  nameRegex: /^[\p{L}\d'-]+(([' \-\p{L}\d])?[\p{L}\d'\- ]*)*$/u, // Allows unicode, numbers, hyphens and apostrophes.
  markupRegex: 1 // @todo
}
