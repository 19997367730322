import './Initialize.css';
import React, {ReactNode, useContext, useEffect, useRef, useState} from 'react';
import {
  IonContent,
  IonPage,
  IonText,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonProgressBar,
  IonCardSubtitle, IonCardContent, IonFooter, IonButton
} from '@ionic/react';
import {useHistory} from "react-router-dom";
import {InitializationProvider} from "../../components/Initialize/InitializationProvider";
import {LocationData} from "../../types/LocationData";
import AppLocationContext from "../../components/Includes/AppLocationContext";
import ConfirmCancelModal from "../../components/Modal/ConfirmCancelModal";
import hsi from "../../lib/HeartSeatInterface";
import {defaultSitTime, localStorageRefs, seatProcessCheckinCode, seatProcessRecordingCode} from "../../Refs";
import {SeatProcessEvent} from "../../types/SeatProcessEvent";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import AuthContext from "../../components/Auth/AuthContext";
import useInitializationData from "../../components/Initialize/InitializationHook";
import usePatientData from "../../components/PatientDetails/PatientHook";
import InitializationService from "../../services/InitializationService/InitializationService";


const InitializeAuto: React.FC = () => {
  const history = useHistory();
  const locationContext = useContext<LocationData>(AppLocationContext);

  const [errorText, setErrorText] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [successText, setSuccessText] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);

  const authContext = useContext(AuthContext);
  const init = useInitializationData();
  const patient = usePatientData();
  const initService = new InitializationService(authContext);

  const [sitSessionData, setSitSessionData] = useState([{sitSessionId: 0, sitStartTime: ''}]);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [isSitTimerRunning, setIsSitTimerRunning] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [hasRecordingError, setHasRecordingError] = useState<boolean>(false);
  const [showRecordingErrorPopover, setShowRecordingErrorPopover] = useState<boolean>(false);
  const [hasTimerCompleted, setHasTimerCompleted] = useState<boolean>(false);
  const [hasCheckinError, setHasCheckinError] = useState<boolean>(false);

  /**
   * This entire section is an unfortunate JS hack to deal with accessing the current useState() values within the
   * fixed autoRecordSeatListener() callback. I couldn't find a better way. The top response on SO also recommended this.
   * I know it sucks. I'm sorry.
   */
  const isRecordingRef = useRef<boolean>();
  isRecordingRef.current = isRecording;
  const isSitTimerRunningRef = useRef<boolean>();
  isSitTimerRunningRef.current = isSitTimerRunning;
  const isUploadingRef = useRef<boolean>();
  isUploadingRef.current = isUploading;
  const hasRecordingErrorRef = useRef<boolean>();
  hasRecordingErrorRef.current = hasRecordingError;

  useEffect(() => {
    document.title = "Initialize your seat";
    unregisterSeatListeners();
    registerSeatListeners();
  }, []);



  const recordingErrorSubheaderTxt = "The initialization sit recording was interrupted before reaching the required " +
    "4 minutes and 30 seconds. Please try again."

  const sitSuccessSubheaderText = "This sit recording meets the criteria for initialization. " +
    "The patient may stand up and remove the blood pressure cuff.";

  const registerSeatListeners = () => {
    hsi.registerProcessEventHandler(autoRecordSeatListener);
    hsi.registerProcessEventHandler(postAutoRecordingErrorSeatListener);
  }

  const unregisterSeatListeners = () => {
    hsi.unregisterAllProcessEventHandlers();
  }

  /**
   * Gets the latest init recording (the one you just recorded) then go to the initialization form screen.
   * @todo this method is duplicated here and in InitializeManual due to the init
   * @todo class var. I would prefer to keep it in one place but that would require some big changes.
   */
  const getRecording = async (): Promise<boolean> => {
    // @todo discuss using contexts vs local storage
    let seatUserId = localStorage.getItem(localStorageRefs.patientId);
    let seatId = localStorage.getItem(localStorageRefs.seatId);
    console.debug('InitializeAutoSitTimerScreen.tsx getRecording() patient', patient);

    if (!seatUserId || !seatId) {
      setBanner(false, 'Please select a patient and seat from Patient Details before initializing.')
      setSitSessionData([]);
      return false;
    }

    try {
      let response = await initService.getLatestInitRecording(Number(seatId), Number(seatUserId));

      console.debug('GetLatestInitRecording', response);

      if (!response.success) {
        console.error(response);
        setBanner(false, 'Unable to retrieve latest initialization recording');
        return false;
      }

      let sitSession = response.data;
      init.data.sitSessionId = sitSession.id;
      init.data.rawRecordingFilename = sitSession.raw_recording_filename;
      init.data.firmwareVersion = sitSession.firmware_version;
      init.data.hardwareVersion = sitSession.hardware_version;
      init.data.seatUserId = sitSession.seat_user_id;
      init.data.dateOfBirth = patient.data.dateOfBirth;
      init.data.sitStartTime = sitSession.sit_start_time;
      init.data.heightFeet = patient.data.seatUserCalibrations?.heightFeet;
      init.data.heightInches = patient.data.seatUserCalibrations?.heightInches;
      init.data.sternalNotch = patient.data.seatUserCalibrations?.sternalNotch;
      init.parseInitializationData(init.data);

      return true;

    } catch (e: any) {
      console.error(e);
      setBanner(false, 'Error getting latest initialization recording');
      return false;
    }
  }

  /**
   * BLE callback handler for the HeartSeatInterface library.
   *
   * @param ev
   */
  const autoRecordSeatListener = (ev: SeatProcessEvent) => {

    console.debug('AUTO SEAT LISTENER', ev);

    if (hasRecordingErrorRef.current) {
      return;
    }

    if (hasRecordingBegun(ev)) {
      console.debug('BEGIN RECORDING!');
      setIsRecording(true);
      setBanner(true, 'Recording has begun. Ensure patient remains seated until the timer completes.');
      startSitTimer();
    }

    if (hasRecordingCompleted(ev)) {

      if (isSitTimerRunningRef.current) {
        console.debug('SIT TIMER STILL RUNNING!!!');
        return handleRecordingError();
      }

      console.debug('END RECORDING!');
      setIsRecording(false);
      setBanner(true, 'Recording has completed.');
    }

    if (hasUploadBegun(ev)) {
      console.debug('BEGIN UPLOADING!');
      setIsUploading(true);
      setBanner(true, 'Upload has begun.');
    }

    if (hasUploadCompleted(ev)) {
      console.debug('END UPLOADING!');
      setIsUploading(false);
      setBanner(true, 'Upload has completed.');
      return handleRecordingSuccess();
    }
  }

  /**
   * If the sitter got up too soon, listen for the seat to complete any pending tasks so clinician
   * can record again. This only does anything if hasRecordingError === true.
   *
   * @param ev
   */
  const postAutoRecordingErrorSeatListener = (ev: SeatProcessEvent) => {

    console.debug('POST AUTO ERROR SEAT LISTENER', ev);

    if (!hasRecordingErrorRef.current) {
      return;
    }

    if (hasSeatCompletedTasksAfterRecordingError(ev)) {
      console.debug('SEAT HAS COMPLETED TASKS AFTER RECORDING ERROR!');
      handleSeatReadyToRecordAfterError();
    }
  }

  const handleSeatReadyToRecordAfterError = () => {
    setBanner(true, 'Seat is ready to re-record.');
    setHasRecordingError(false);
    resetRecordingStates();
  }

  /**
   * Set state to show that an error occurred and activate the postAutoRecordingErrorSeatListener method.
   * The postAutoRecordingErrorSeatListener should reset things once it sees the seat is done uploading
   * and checking in.
   */
  const handleRecordingError = async () => {
    console.debug('RECORDING ERROR');
    setIsUploading(false); // Hide upload progress element if it's displayed
    setShowRecordingErrorPopover(true);
    setHasRecordingError(true);
    resetSitTimer();
    unregisterSeatListeners();
    registerSeatListeners();

    try {
      setBanner(false, 'Please wait for seat to complete pending tasks then try again.');
      await hsi.handleCmd('checkin', null);
    } catch (e: any) {
      clearBanner();
      setHasCheckinError(true);
    }
  }

  const returnToPairAfterCheckinError = () => {
    unregisterSeatListeners();
    setHasRecordingError(false);

    setTimeout(function() {
      history.push('/pair')
      locationContext.returnView = '/pair';
    }, 50);
  }

  const resetRecordingStates = () => {
    setIsRecording(false);
    setIsSitTimerRunning(false);
    setIsUploading(false);
  }

  const resetAllStates = () => {
    setIsRecording(false);
    setIsSitTimerRunning(false);
    setIsUploading(false);
    setShowRecordingErrorPopover(false);
    setHasRecordingError(false);
  }

  const handleTryAgain = () => {
    setShowRecordingErrorPopover(false);
  }

  const hasRecordingBegun = (ev: SeatProcessEvent): boolean => {
    return !isRecordingRef.current && ev.proc_magic === seatProcessRecordingCode && typeof ev.error === 'undefined';
  }

  const hasRecordingCompleted = (ev: SeatProcessEvent): boolean => {
    return !!isRecordingRef.current && ev.proc_magic === seatProcessRecordingCode && typeof ev.error !== 'undefined';
  }

  const hasUploadBegun = (ev: SeatProcessEvent): boolean => {
    return !isUploadingRef.current && ev.proc_magic === seatProcessCheckinCode && typeof ev.error === 'undefined';
  }

  const hasUploadCompleted = (ev: SeatProcessEvent): boolean => {
    return !!isUploadingRef.current && ev.proc_magic === seatProcessCheckinCode && typeof ev.error !== 'undefined';
  }

  const hasSeatCompletedTasksAfterRecordingError = (ev: SeatProcessEvent): boolean => {
    return ev.proc_magic === seatProcessCheckinCode && typeof ev.error !== 'undefined';
  }

  const setTime = ({remainingTime}: any): any => {

    let minutes = Math.floor(remainingTime / 60);
    let seconds = remainingTime - minutes * 60;
    let formattedSeconds = seconds >= 10 ? seconds : '0' + seconds;

    // @todo revisit logic here
    if (remainingTime === 0) {
      return (
        <div className="timer">
          <div className="value">{minutes}:{formattedSeconds}</div>
        </div>
      );
    }

    return (
      <div className="timer">
        <div className="value">{minutes}:{formattedSeconds}</div>
      </div>
    );
  };

  const handleRecordingSuccess = async () => {
    resetSitTimer();
    unregisterSeatListeners();
    await handleNext();
  }

  const handleBack = () => {
    unregisterSeatListeners();
    resetAllStates();
    handleBackToStart();
  }

  // Get the recording from the cloud and move to the initialization form.
  const handleNext = async () => {
    resetAllStates();

    if (await getRecording()) {
      setHasTimerCompleted(false);
      setTimeout(function() {
        handleFormScreenLink();
      }, 50);
    }
  }

  const [sitTimerContent, setSitTimerContent] = useState<ReactNode>(
    <CountdownCircleTimer
      isPlaying={false}
      duration={defaultSitTime}
      colors={["#6a6efe", "#6a6efe"]}
      colorsTime={[10, 0]}
      strokeWidth={4}
      onComplete={() => ({shouldRepeat: false, delay: 1})}>
      {setTime}
    </CountdownCircleTimer>);

  /**
   * For auto recording, this command will be triggered when the patient sits on the seat.
   */
  const startSitTimer = (): any => {
    setIsSitTimerRunning(true);

    try {
      setSitTimerContent(<CountdownCircleTimer
        key={0}
        isPlaying={true}
        duration={defaultSitTime}
        colors={["#6A6EFF", "#6A6EFF"]}
        colorsTime={[10, 0]}
        strokeWidth={4}
        onComplete={() => {
          setIsSitTimerRunning(false);
          setHasTimerCompleted(true);
          return {shouldRepeat: false, delay: 1}
        }}
      >
        {setTime}
      </CountdownCircleTimer>);

    } catch (e: any) {
      setBanner(false, 'Application error. Failed to create recording.');
      console.error('APP ERROR', e);
    }
  }

  const resetSitTimer = (): any => {

    clearBanner();

    setSitTimerContent(
      <CountdownCircleTimer
        key={1}
        isPlaying={false}
        duration={defaultSitTime}
        colors={["#6A6EFF", "#6A6EFF"]}
        colorsTime={[10, 0]}
        strokeWidth={4}
        onComplete={() => ({shouldRepeat: false, delay: 1})}>
        {setTime}
      </CountdownCircleTimer>);
  }

  const setBanner = (success: boolean, message: string) => {
    if (success) {
      setSuccess(true);
      setSuccessText(message);
      setError(false);
    } else {
      setError(true);
      setErrorText(message);
      setSuccess(false);
    }
  }

  const clearBanner = () => {
    setSuccess(false);
    setError(false);
  }

  const handleFormScreenLink = () => {
    history.push('/initialize/form')
    locationContext.returnView = '/initialize/form';
  }

  const handleBackToStart = () => {
    clearBanner();
    history.push('/initialize')
    locationContext.returnView = '/initialize';
  }

  return (
    <IonPage>
      <InitializationProvider>
        <IonContent fullscreen className="container">
          <IonCard className={error ? 'ion-show flash-message' : 'ion-hide flash-message'}>
            <IonText className="danger">
              {errorText}
            </IonText>
          </IonCard>
          <IonCard className={success ? 'ion-show flash-message' : 'ion-hide flash-message'}>
            <IonText className="success">
              {successText}
            </IonText>
          </IonCard>
          <IonCard className="standard-container">
            <IonCardHeader>
              <IonCardTitle>Monitor the recording</IonCardTitle>
              {isUploading ? <IonProgressBar type="indeterminate" className="upload-progress"></IonProgressBar> : null}
              {hasRecordingError ? <IonProgressBar type="indeterminate" className="error-progress" color="danger"></IonProgressBar> : null}
              <IonCardSubtitle className="m-t-20 m-b-20">The timer will begin counting down once the patient is seated on the Heart Seat.
                The patient must complete the full 4 minute 30 second sit before proceeding to the next step.
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent className="initialize-auto-timer-content">
              <div className="timer-wrapper">
                {sitTimerContent}
              </div>
            </IonCardContent>
            <IonFooter className="standard-container-footer">
              <IonButton
                className="btn btn-back ion-float-right"
                disabled={isRecording || isUploading || hasRecordingError}
                onClick={handleBack}>Back
              </IonButton>
            </IonFooter>
            <ConfirmCancelModal
              isOpen={showRecordingErrorPopover}
              headerText="Insufficient sit length"
              subheaderText={recordingErrorSubheaderTxt}
              onButtonAction1={handleTryAgain}
              actionButtonText1="Try Again"
              showWarningIcon={true}
            />
            <ConfirmCancelModal
              isOpen={hasTimerCompleted}
              headerText="Sit complete!"
              subheaderText={sitSuccessSubheaderText}
              onButtonAction1={() => setHasTimerCompleted(false)}
              actionButtonText1="Continue"
              showWarningIcon={false}
              bigHeader
            />
            <ConfirmCancelModal
              isOpen={hasCheckinError}
              headerText="Command check_in failed"
              subheaderText="Your connection may have been lost. Please pair your seat again."
              onButtonAction1={returnToPairAfterCheckinError}
              onButtonAction2={()=> setHasCheckinError(false)}
              actionButtonText1="Pair Seat"
              actionButtonText2="Dismiss"
              showWarningIcon={true}
            />
          </IonCard>
        </IonContent>
      </InitializationProvider>
    </IonPage>
  );
};

export default InitializeAuto;

