import {IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import {Redirect, Route} from 'react-router-dom';
import React, {useState} from "react";
import {localStorageRefs} from "./Refs";
import Menu from './components/Menu/Menu';
import Login from './pages/Login/Login';
import Logout from './pages/Logout/Logout';
import AccountSettings from "./pages/AccountSettings/AccountSettings";
import WiFi from './pages/WiFi/WiFi';
import ModalProvider from "./components/Modal/ModalProvider";
import ModalNotification from "./components/Modal/Modal";
import SeatSettingsProvider from "./components/SeatSettings/SeatSettingsProvider";
import HeartSeatLogProvider from "./components/SeatSettings/HeartSeatLogProvider";
import {PatientProvider} from "./components/PatientDetails/PatientProvider";
import SessionExpirationModalProvider from "./components/Modal/SessionExpirationModalProvider";
import SessionExpirationModalNotification from "./components/Modal/SessionExpirationModal";
import VerifyEmail from "./pages/VerifyEmail/VerifyEmail";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Pair from "./pages/Pair/Pair";
import NewPatient from "./pages/PatientDetails/NewPatient";
import ExistingPatient from "./pages/PatientDetails/ExistingPatient";
import ConfirmPatient from "./pages/PatientDetails/ConfirmPatient";
import PatientForm from "./pages/PatientDetails/PatientForm";
import InitializeStart from "./pages/Initialize/InitializeStart";
import InitializeAuto from "./pages/Initialize/InitializeAuto";
import InitializeManual from "./pages/Initialize/InitializeManual";
import InitializeManualConfirm from "./pages/Initialize/InitializeManualConfirm";
import InitializeExisting from "./pages/Initialize/InitializeExisting";
import InitializeForm from "./pages/Initialize/InitializeForm";
import InitializeSuccess from "./pages/Initialize/InitializeSuccess";
import InitializeFailure from "./pages/Initialize/InitializeFailure";
import {InitializationProvider} from "./components/Initialize/InitializationProvider";
import CompleteUserRegistration from "./pages/UserRegistration/CompleteUserRegistration";
import SeatDebugModalProvider from "./components/Modal/SeatDebugModalProvider";
import SeatDebugModal from "./components/Modal/SeatDebugModal";
import SeatDebugModalIcon from "./components/Modal/SeatDebugModalIcon";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/override.css';
import './theme/container.css';
import './theme/form.css';

setupIonicReact();

const App: React.FC = () => {
  const [debug, setDebug] = useState<boolean>(false);

  return (
    <IonApp>
      <IonReactRouter>
        <SessionExpirationModalProvider>
          <SessionExpirationModalNotification/>
          <ModalProvider>
            <ModalNotification/>
            <SeatSettingsProvider>
              <HeartSeatLogProvider>

                <SeatDebugModalProvider>
                  <SeatDebugModal/>
                  <SeatDebugModalIcon display={debug}/>

                  <IonSplitPane contentId="main" when="xs">
                    <Menu/>
                    <IonRouterOutlet id="main">
                      <Route path="/" exact={true}>
                        <Redirect to="/login"/>
                      </Route>
                      <Route path="/login" exact={true}>
                        <Login/>
                      </Route>
                      <Route path="/logout" exact={true}>
                        <Logout toggleDebug={setDebug}/>
                      </Route>

                      <Route path="/reset-password/:token">
                        <ResetPassword/>
                      </Route>
                      <Route path="/verify-email/:token">
                        <VerifyEmail/>
                      </Route>
                      <Route path="/user-complete-registration/:token">
                        <CompleteUserRegistration/>
                      </Route>

                      {/* For whatever reason PatientProvider needs to be up here or routing to /pair sometimes takes
                        you to the /wifi page and the Wifi page useEffect() hook doesn't work as expected.
                    */}
                      <PatientProvider>
                        <Route path="/pair" exact={true}>
                          <Pair toggleDebug={setDebug}/>
                        </Route>
                        <Route path="/wifi" exact={true}>
                          <WiFi/>
                        </Route>

                        {/* Patient Details */}
                        <Route path="/patient-details" exact={true}>
                          {
                            localStorage.getItem(localStorageRefs.patientId) === '0'
                              ? <Redirect to='/patient-details/new'/>
                              : <Redirect to='/patient-details/existing'/>
                          }
                        </Route>
                        <Route path="/patient-details/new" exact={true}>
                          <NewPatient/>
                        </Route>
                        <Route path="/patient-details/existing" exact={true}>
                          <ExistingPatient/>
                        </Route>
                        <Route path="/patient-details/confirm" exact={true}>
                          <ConfirmPatient/>
                        </Route>
                        <Route path="/patient-details/form" exact={true}>
                          <PatientForm/>
                        </Route>

                        {/* Initialization */}
                        <InitializationProvider>
                          <Route path="/initialize" exact={true}>
                            <InitializeStart/>
                          </Route>
                          <Route path="/initialize/auto" exact={true}>
                            <InitializeAuto/>
                          </Route>
                          <Route path="/initialize/manual" exact={true}>
                            <InitializeManual/>
                          </Route>
                          <Route path="/initialize/manual/confirm" exact={true}>
                            <InitializeManualConfirm/>
                          </Route>
                          <Route path="/initialize/existing" exact={true}>
                            <InitializeExisting/>
                          </Route>
                          <Route path="/initialize/form" exact={true}>
                            <InitializeForm/>
                          </Route>
                          <Route path="/initialize/success" exact={true} >
                            <InitializeSuccess toggleDebug={setDebug}/>
                          </Route>
                          <Route path="/initialize/failure" exact={true}>
                            <InitializeFailure toggleDebug={setDebug}/>
                          </Route>

                          <Route path="/account-settings" exact={true}>
                            <AccountSettings/>
                          </Route>

                        </InitializationProvider>

                      </PatientProvider>
                    </IonRouterOutlet>
                  </IonSplitPane>
                </SeatDebugModalProvider>

              </HeartSeatLogProvider>
            </SeatSettingsProvider>
          </ModalProvider>
        </SessionExpirationModalProvider>

      </IonReactRouter>
    </IonApp>
  );
};

export default App;
