import './PairScreens.css';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle, IonCol,
  IonContent, IonFooter, IonGrid, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonNote,
  IonPage,
  IonProgressBar, IonRow
} from "@ionic/react";
import {bluetoothOutline, chevronForwardOutline, searchOutline} from "ionicons/icons";
import ConfirmCancelModal from "../../components/Modal/ConfirmCancelModal";
import useSeatLog from "../../components/SeatSettings/HeartSeatLogHook";
import {PairData} from "../../types/PairData";
import PairContext from "../../components/Pair/PairContext";
import useSeatSettings from "../../components/SeatSettings/SeatSettingsHook";
import {Image} from "../../types/Image";
import {Token} from "../../types/Token";
import AuthContext from "../../components/Auth/AuthContext";
import CloudApiService from "../../services/CloudApiService/CloudApiService";
import {useHistory} from "react-router-dom";
import {LocationData} from "../../types/LocationData";
import AppLocationContext from "../../components/Includes/AppLocationContext";
import useModalError from "../../components/Modal/ModalHook";
import hsi from "../../lib/HeartSeatInterface";
import {seatBleReconnectTimeout} from "../../Refs";


interface ContainerProps {
  toggleDebug: Function
}

const Pair: React.FC<ContainerProps> = ({toggleDebug}) => {

  const hsl = useSeatLog();
  const [serialNumber, setSerialNumber] = useState<string>('');
  const [pinInputRequired, setPinInputRequired] = useState<boolean>(false);
  const [seatError, setSeatError] = useState<boolean>(false);
  const [seatErrorText, setSeatErrorText] = useState<string>('');
  const pairContext = useContext<PairData>(PairContext);
  const seatSettings = useSeatSettings();
  const [isPairing, setIsPairing] = useState(false);

  const [pin, setPin] = useState<string>('');
  const [isPinValid, setIsPinValid] = useState<boolean>(true);

  const pairSeatImg: Image = {
    src: './assets/pair-seat-instructions-serial-number.png',
    text: 'Instructions for connecting to the Heart Seat'
  };

  const auth = useContext<Token>(AuthContext);
  const ApiService = new CloudApiService(auth);
  const history = useHistory();
  const locationContext = useContext<LocationData>(AppLocationContext);
  const bluetoothErrorhandler = useModalError();

  useEffect(() => {
    document.title = "Pair to a seat via Bluetooth";
  }, []);

  /**
   * Set the serial number stateful variable. This way when
   * it changes the state is recompiled.
   *
   * @param val
   */
  const handleSerialNumberChange = async (val: string) => {
    setSerialNumber(val);
  }

  /**
   * Trigger the native bluetooth functionality and attempt to connect to the seat. If we are successful, attempt
   * to connect with pin from the database. If the pin is wrong or nonexistent, the
   * connectWithPin() error handler will route the user to the pin page.
   *
   * @param serialNumber
   */
  const handleBleConnect = async (serialNumber: string) => {
    console.debug('hsi.getBleContextFromBrowser requested');
    let bluetoothCtx = await hsi.getBleContextFromBrowser(serialNumber.toUpperCase());
    console.debug('hsi.getBleContextFromBrowser received');
    if (!bluetoothCtx) {
      return;
    }

    setIsPairing(true);
    pairContext.bleContext = bluetoothCtx;

    try {
      console.debug('hsi.connect requested');
      await hsi.connect(bluetoothCtx);
      console.debug('hsi.connect received');
      console.debug('get_fw_version requested');
      await hsi.handleCmd('get_fw_version', null).then((response: number) => {
        console.debug('get_fw_version received');
        pairContext.firmwareVersion = response;
        setIsPairing(false);
      });
      pairContext.serialNumber = bluetoothCtx.name.substring(bluetoothCtx.name.indexOf('_') + 1);
      await connectWithPin();
    } catch (e: any) {
      setIsPairing(false);
      console.error(e);
    }
  }

  /**
   * Attempt to reconnect to a seat if the BLE connection has disconnected.
   */
  const reconnectBleAfterDisconnect = async () => {
    try {
      console.debug('Attempting ReconnectBleAfterDisconnect()...');
      console.debug('BLE Context:', pairContext.bleContext);
      console.debug(`BLE auth token: ${pairContext.bleAuthToken}`);
      await hsi.connect(pairContext.bleContext);
      await hsi.handleCmd('ble_auth', pairContext.bleAuthToken);
      console.debug('ReconnectBleAfterDisconnect successful');
      return true;

    } catch (e: any) {
      console.debug('ReconnectBleAfterDisconnect failed');
      return false;
    }
  }

  /**
   * Fetch the seat pin from the cloud and connect to the seat, if we fail to
   * retrieve a pin, allow the user to enter a pin.
   */
  const connectWithPin = async () => {
    try {
      ApiService.getSeatBySerialNumber(pairContext.serialNumber).then(async (response: any) => {
        console.debug('PairSerialScreen -> getSeatBySerialNumber()', response);

        // If there was an error retrieving the seat for some reason. A 401 will return another modal via Axios hook.
        if (!response.success && response.error.code !== 401) {
          setSeatError(true);
          setSeatErrorText('Error retrieving seat. Please check that the seat has been registered and configured.');
          return;
        }

        let pin: string | null = response.data.bleAuthToken;

        try {
          await hsi.handleCmd('ble_auth', pin);

          await hsi.handleCmd('get_ble_auth_token', null).then((response: any) => {
            if (response) {
              seatSettings.parseSettings(response);
              pairContext.bleAuthToken = response;
              hsi.unregisterAllConnectionStatusHandler();
              hsi.unregisterAllDebugHandlers();
              hsi.unregisterAllMsgErrorHandlers();

              registerConnectionStatusHandler();

              hsi.registerDebugHandler(hsl.onHsLogMsg);
              hsi.registerMsgErrorHandler(hsl.onHsLogMsg);
              toggleDebug(true);

              hsi.unregisterAllDebugHandlers();
              hsi.unregisterAllMsgErrorHandlers();
              hsi.registerDebugHandler(hsl.onHsLogMsg);
              hsi.registerMsgErrorHandler(hsl.onHsLogMsg);

              history.push('/wifi');
              locationContext.returnView = '/wifi';
            }
          });
        } catch (e: any) {
          toggleDebug(false);
          console.error(e);
          setPinInputRequired(true);
        }

      });

    } catch (e: any) {
      setPinInputRequired(true);
    }
  }

  // Hack to submit form if enter key is pressed.
  const checkEnter = async (e: any) => {
    if (e.key === 'Enter') {
      await handleBleConnect(serialNumber);
    }
  }

  /**
   * Register listener callback function for monitoring BLE connection status.
   */
  const registerConnectionStatusHandler = async () => {
    console.debug('registerConnectionStatusHandler has been set.');
    hsi.registerConnectionStatusHandler(async (connStatus: string) => {
      console.debug('BLE connection status handler callback:', connStatus);
      if (connStatus === 'connecting' || connStatus === 'connected') {
        return;
      }

      /**
       * If the seat disconnected from BLE, attempt to reconnect. The reconnect process doesn't appear to have
       * a timeout, so cause the error modal to appear if reconnection isn't successful after 10 seconds.
       */
      console.debug('Attempting to reconnect to seat.');

      let errorHandler = setTimeout(function() {
        console.debug('Seat reconnect timeout exceeded. Error modal will display.');
        toggleDebug(false);
        bluetoothErrorhandler.addError(
          'Seat Disconnected',
          'You are no longer connected to a Heart Seat. Please pair to a seat in order to continue.',
          'Pair Seat',
          '/pair'
        );
      }, seatBleReconnectTimeout);

      if (await reconnectBleAfterDisconnect()) {
        clearTimeout(errorHandler);
        console.debug('BLE reconnect successful.');
        return;
      }
    });
  }

  /**
   * Pin screen functions
   */

  /**
   * When the pin changes in the input, strip out any non digit characters and
   * set the valid state if we have a 6 digit string. Otherwise, trigger the invalid
   * state.
   *
   * @param ev
   */
  const handlePinChange = (ev: Event) => {
    const val = (ev.target as HTMLInputElement).value;
    const parsedVal = val.replace(/[^0-9]/g, '');

    if (parsedVal) {
      setPin(val);
    } else {
      setPin('');
      setIsPinValid(false);
    }

    if (parsedVal.length === 6) {
      setIsPinValid(true);
    } else if (val.length === 0) {
      setIsPinValid(true);
    } else {
      setIsPinValid(false);
    }
  }

  /**
   * For the pin screen. If we have a pin that we think could be valid, send it to the seat for authentication. The seat will only
   * return a response from get_ble_auth_token if a valid pin was provided. WE then trigger the bluetooth pulse to
   * ensure that connectivity with the seat is not interrupted, and then move the user to the Wi-Fi page.
   */
  const handleBleAuth = async () => {
    if (pin.length !== 6 || !isPinValid) {
      setIsPinValid(false);
      return;
    }

    try {
      await hsi.handleCmd('ble_auth', pin);

      await hsi.handleCmd('get_ble_auth_token', null).then((response: any) => {
        if (response) {
          pairContext.bleAuthToken = response;
          hsi.unregisterAllConnectionStatusHandler();
          hsi.unregisterAllDebugHandlers();
          hsi.unregisterAllMsgErrorHandlers();

          registerConnectionStatusHandler();

          hsi.registerDebugHandler(hsl.onHsLogMsg);
          hsi.registerMsgErrorHandler(hsl.onHsLogMsg);
          toggleDebug(true);
          setPinInputRequired(false);
          history.push('/wifi');
          locationContext.returnView = '/wifi';
          seatSettings.setButtonClass('off');
        }
      });
    } catch (e: any) {
      setIsPinValid(false);
      window.clearInterval(window.blePulseHeartbeatInterval);
      toggleDebug(false);
      console.error(e);
    }
  }

  const handleBack = () => {
    setPinInputRequired(false);
  }


  const pairSerialScreen =
    <>
      <IonContent fullscreen className="container">
        <IonCard className="standard-container">
          <IonCardHeader>
            <IonCardTitle className="m-b-20">Pair with your selected seat</IonCardTitle>
            {isPairing ? <IonProgressBar type="indeterminate"></IonProgressBar> : null}
            <IonCardSubtitle className="m-t-20">After selecting the Pair Seat button, a list of available seats will
              appear in a pop-up menu. You can filter the list of available seats by typing the first digits of your
              seat's serial number into the Filter field.</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent className="standard-container-content pair-select-serial-number">
            <IonGrid>
              <IonRow>
                <IonCol size="12" size-md="4">
                  <IonItem className="serial-number-input">
                    <IonIcon slot="start" ios={searchOutline} md={searchOutline}></IonIcon>
                    <IonInput className="serial-number-input"
                              autocapitalize="characters"
                              maxlength={16}
                              placeholder="Filter"
                              onIonChange={(e: any) => handleSerialNumberChange(e.detail.value)}
                              onKeyUp={(e: any) => checkEnter(e)}
                    ></IonInput>
                  </IonItem>
                </IonCol>
                <IonCol size="12" size-md="4">
                  <IonButton className="serial-number-submit" disabled={isPairing}
                             onClick={() => handleBleConnect(serialNumber)}>
                    Pair Seat
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonAccordionGroup className="pair-accordion no-ripple">
                    <IonAccordion value="first" className="no-ripple" toggleIconSlot="start"
                                  toggleIcon={chevronForwardOutline}>
                      <IonItem slot="header" className="color-app">
                        <IonLabel className="pair-accordion-text">Show me where to find the seat serial
                          number</IonLabel>
                      </IonItem>
                      <IonItem slot="content" lines="none">
                        <IonImg src={pairSeatImg.src} alt={pairSeatImg.text}
                                className="ion-align-self-center seat-image"/>
                      </IonItem>
                    </IonAccordion>
                  </IonAccordionGroup>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
          <ConfirmCancelModal
            isOpen={seatError}
            headerText="Error retrieving seat"
            subheaderText={seatErrorText}
            onButtonAction1={() => {
              setSeatError(false)
            }}
            actionButtonText1="Ok"
            bigHeader
            showWarningIcon={true}
          />
        </IonCard>
      </IonContent>
    </>

  const pairPinScreen =
    <>
      <IonContent fullscreen className="container">
        <IonCard className="standard-container">
          <IonCardHeader>
            <IonCardTitle className="m-b-20">Enter the seat PIN</IonCardTitle>
            <IonCardSubtitle className="m-t-20 m-b-20">for seat <strong
              className="ion-text-capitalize">{pairContext.serialNumber}</strong></IonCardSubtitle>
            <IonCardSubtitle>You can find the 6-digit seat PIN on the seat label, located on the battery door on the
              bottom
              of the seat</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent className="standard-container-content pair-container">
            <IonGrid>
              <IonRow>
                <IonCol size="6">
                  <IonItem
                    className={`${isPinValid && 'pin-input ion-valid'} ${!isPinValid && 'pin-input ion-invalid'}`}>
                    <IonIcon slot="start" ios={bluetoothOutline} md={bluetoothOutline}></IonIcon>
                    <IonInput className="pin-input"
                              maxlength={6}
                              placeholder="__ __ __ __ __ __"
                              type="text"
                              onIonChange={(e: any) => handlePinChange(e)}
                              value={pin}
                    ></IonInput>
                    <IonNote slot="error">The seat PIN is Incorrect</IonNote>
                  </IonItem>
                </IonCol>
                <IonCol size="6"></IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
          <IonFooter className="standard-container-footer">
            <IonButton className="btn btn-type-code ion-float-right" onClick={handleBleAuth}>
              Next
            </IonButton>
            <IonButton className="btn btn-type-code ion-float-right" onClick={handleBack}>
              Back
            </IonButton>
          </IonFooter>
        </IonCard>
      </IonContent>
    </>

  return (
    <IonPage>
      {pinInputRequired ? pairPinScreen : pairSerialScreen}
    </IonPage>
  );
};

export default Pair;
