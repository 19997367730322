import './SeatDebugModal.css';
import React, {useContext, useEffect, useRef, useState} from "react";
import useSeatDebugModal from "./SeatDebugModalHook";
import {
  IonButton,
  IonCard, IonCol,
  IonContent, IonFooter,
  IonGrid,
  IonHeader, IonIcon,
  IonItem,
  IonModal,
  IonRow,
  IonText,
  IonTitle
} from "@ionic/react";
import {useLocation, useHistory} from "react-router-dom";
import PairContext from "../Pair/PairContext";
import useSeatSettings from "../SeatSettings/SeatSettingsHook";
import useSeatLog from "../SeatSettings/HeartSeatLogHook";
import AuthContext from "../Auth/AuthContext";
import CloudApiService from "../../services/CloudApiService/CloudApiService";
import hsi from "../../lib/HeartSeatInterface";
import {SeatProcessEvent} from "../../types/SeatProcessEvent";
import {firmwareCutoffVersion, localStorageRefs, seatProcessCheckinCode} from "../../Refs";
import semver from "semver/preload";
import moment from "moment/moment";
import ConfirmCancelModal from "./ConfirmCancelModal";
import SessionHelper from "../../helpers/SessionHelper";
import {close} from "ionicons/icons";

function SeatDebugModal() {
  const {showPanel, removeShowPanel} = useSeatDebugModal();
  const history = useHistory();
  const location = useLocation();
  const pairContext = useContext(PairContext);
  const seatSettings = useSeatSettings();
  const hsl = useSeatLog();
  let logContainer = document.getElementById("log-container");
  const auth = useContext(AuthContext);
  const cloudApiService = new CloudApiService(auth);
  const replaceBatterySubheaderText = "Follow the instructions in the Heart Seat Technical Manual to replace the seat's " +
    "batteries. Finalize the battery replacement by selecting the RESET BATTERY button."
  const replaceBatteryFooterText = "You must select RESET BATTERY for the battery level to reset properly in Casana Cloud."

  const [noSeatSelected, setNoSeatSelected] = useState<boolean>(false);
  const [isReplaceBatterySelected, setIsReplaceBatterySelected] = useState<boolean>(false);
  const [isReplaceBatterySuccessful, setIsReplaceBatterySuccessful] = useState<boolean>(false);
  const [isSeatDisconnected, setIsSeatDisconnected] = useState<boolean>(false);
  const [isReplaceBatteryFailure, setIsReplaceBatteryFailure] = useState<boolean>(false);
  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);
  const [hasBleConnectionError, setHasBleConnectionError] = useState<boolean>(false);
  const [isForceCheckingIn, setIsForceCheckingIn] = useState<boolean>(false);
  const isForceCheckingInRef = useRef<boolean>();
  isForceCheckingInRef.current = isForceCheckingIn;

  /**
   * Make sure that all the log handlers are only registered once. Multiple handlers will cause duplicated log lines.
   * If we are not in the process of loading the page then just register the handlers. If we are in the process of
   * loading the page then create a callback to check connection to the seat. This makes sure the user is redirected
   * away from the debug page only on page reloads, as the user would not be connected to a seat and this page would be
   * broken.
   */
  useEffect(() => {
    setButtonsDisabled(isOnInitializationRoute());

    // Do nothing until seat is connected.
    if (!SessionHelper.hasSeat()) {
      return;
    }

    if (document.readyState === 'complete') {
      hsi.registerDebugHandler(scrollLogView);
      hsi.registerMsgErrorHandler(scrollLogView);
    } else {
      window.addEventListener('load', onPageLoad, false);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, [hsl.logData, location]);

  /**
   * This is used to trigger the check to seat connection only on fresh page loads. This allows us to redirect away
   * from the seat settings page if the user is not connected to a seat when the page loads. Otherwise, if the wizard
   * loses connection to the seat the background process will pick it up and show the modal that redirects the user to
   * the pair screen correctly.
   */
  const onPageLoad = () => {
    checkSeatConnection();
  };

  /**
   * Used to determine if the user is on an initialization route so that the modal buttons will be disabled
   * so as not to interfere with the event listeners required to check for seat events.
   */
  const isOnInitializationRoute = () => {
    return location.pathname.includes('initialize');
  }

  /**
   * Makes sure the log view is always showing the latest log actions.
   */
  const scrollLogView = () => {
    if (logContainer) {
      logContainer.scrollTop = logContainer.scrollHeight;
    }
  }

  /**
   * Force the seat to check in with the cloud URL. Can optionally also upload all pending recordings.
   *
   * @param doUpload
   */
  const forceCheckinWaitImpl = async (doUpload: boolean) => {
    setIsForceCheckingIn(true);
    if (doUpload) await hsi.handleCmd('checkin_with_upload', null);
    else await hsi.handleCmd('checkin', null);

    console.debug('forceCheckinWaitImpl - CHECKIN');
    hsi.registerProcessEventHandler(forceCheckinListener);

    for (let i = 0; i < 45; i++) {
      await new Promise((r) => setTimeout(r, 1000));

      if (!isForceCheckingInRef.current) {
        console.debug("Checkin was successful!");
        hsi.unregisterAllProcessEventHandlers();
        return;
      }
    }

    /* We timed out. Throw an error */
    hsi.unregisterAllProcessEventHandlers();
    throw new Error('checkin timed out');
  };

  const forceCheckinListener = (ev: SeatProcessEvent) => {
    if (hasSeatCompletedCheckin(ev)) {
      setIsForceCheckingIn(false);
    }
  }

  const hasSeatCompletedCheckin = (ev: SeatProcessEvent): boolean => {
    return ev.proc_magic === seatProcessCheckinCode && typeof ev.error !== 'undefined';
  }

  /**
   * Allow us to move the user away from the settings page if a seat is not connected.
   */
  const checkSeatConnection = () => {
    if (pairContext.firmwareVersion && semver.gte(pairContext.firmwareVersion.toString(), firmwareCutoffVersion)) {
      checkSeatConnectionForNewFirmware();
    } else {
      checkSeatConnectionForOldFirmware();
    }
  }

  const checkSeatConnectionForOldFirmware = () => {
    hsi.handleCmd('file_get_info', "user_cfg").catch((e) => {
      if (e.toString() === 'Error: not connected') {
        history.push('/pair');
        seatSettings.setButtonClass('off');
      } else {
        console.error(e);
      }
    });
  }

  const checkSeatConnectionForNewFirmware = () => {
    hsi.handleCmd('get_scrubbed_user_cfg').catch((e) => {
      if (e.toString() === 'Error: not connected') {
        history.push('/pair');
        seatSettings.setButtonClass('off');
      } else {
        console.error(e);
      }
    });
  }

  /**
   * Disconnect from the seat over BLE.
   **/
  const disconnect = async () => {
    hsl.onUserAction('seat disconnect');

    // Unregister handler so the app doesn't try to reconnect to the seat once it's disconnected.
    hsi.unregisterAllConnectionStatusHandler();

    hsi.disconnect();
    setIsSeatDisconnected(true);
  }

  /**
   * Disconnect from the seat over BLE but don't display modal or unregister the connection status handler.
   * This button will only appear if the env variable REACT_APP_DEBUG_MODE is set to true.
   **/
  const disconnectDebug = async () => {
    hsl.onUserAction('seat disconnect');
    hsi.disconnect();
  }

  /**
   * For returning to the pair screen after disconnecting a seat.
   */
  const returnToPairAfterDisconnect = async () => {
    setIsSeatDisconnected(false);
    removeShowPanel();
    setTimeout(function () {
      history.push('/pair');
    }, 50);
  }

  const returnToPairAfterCommandError = async () => {
    setHasBleConnectionError(false);
    removeShowPanel();
    setTimeout(function () {
      history.push('/pair');
    }, 50);
  }

  /**
   * User acton to force the seat to check in with the cloud.
   */
  const forceCheckIn = async () => {
    try {
      hsl.onUserAction('checkin');
      await forceCheckinWaitImpl(false);
      await refreshSeatData();
    } catch (e: any) {
      setHasBleConnectionError(true);
    }
  }

  /**
   * User action to force the seat to upload all pending recordings to the cloud.
   */
  const forceUpload = async () => {
    try {
      hsl.onUserAction('checkin with recording upload');
      await forceCheckinWaitImpl(true);
      await refreshSeatData();
    } catch (e: any) {
      setHasBleConnectionError(true);
    }
  }

  const showReplaceBatteryModal = () => {
    setIsReplaceBatterySelected(true);
  }

  /**
   * User action to reset the seat battery level via Cloud API.
   */
  const handleReplaceBattery = async () => {

    let seatId = localStorage.getItem(localStorageRefs.seatId);

    if (!seatId) {
      setIsReplaceBatterySelected(false);
      setNoSeatSelected(true);
      return;
    }

    cloudApiService.resetSeatBattery(seatId).then(async (response: any) => {
      if (response.success) {
        handleReplaceBatterySuccess();
        await refreshSeatData();
      } else {
        handleReplaceBatteryFailure();
        console.error(response);
      }

    }).catch((error: any) => {
      console.error(error);
    });
  }

  const handleCancelReplaceBattery = () => {
    setIsReplaceBatterySelected(false);
  }

  const handleReplaceBatterySuccess = () => {
    setIsReplaceBatterySelected(false);
    setIsReplaceBatterySuccessful(true);
  }

  const handleReplaceBatteryFailure = () => {
    setIsReplaceBatterySelected(false);
    setIsReplaceBatteryFailure(true);
  }

  const handleCloseBatterySuccessful = () => {
    setIsReplaceBatterySuccessful(false);
  }

  const handleCancelAfterReplaceBatteryFailure = () => {
    setIsReplaceBatteryFailure(false);
  }

  const handleRetryAfterReplaceBatteryFailure = async () => {
    setIsReplaceBatteryFailure(false);
    await handleReplaceBattery();
  }

  const handleNoSeatSelected = () => {
    setNoSeatSelected(false);
  }

  /**
   * User action to reboot the seat.
   */
  const reboot = async () => {
    try {
      hsl.onUserAction('seat reboot');
      await hsi.handleCmd('software_reset', null);
      await new Promise((r) => setTimeout(r, 3000));
    } catch (e: any) {
      setHasBleConnectionError(true);
    }
  };

  /**
   * Clear the log container.
   */
  const clear = async () => {
    hsl.setLogData([]);
  }

  /**
   * Copy all text in the log container to the clipboard.
   */
  const copy = () => {
    const data = assembleLogData();
    navigator.clipboard.writeText(data);
    hsl.onUserAction('copy logs to clipboard');
  }

  /**
   * Convenience method for converting the log to a string
   **/
  const assembleLogData = () => {
    return hsl.logData.map((entry: any) => `${hsl.getLogLineString(entry)}\n`).join('');
  }

  /**
   * Download the contents of the log container as a file.
   */
  const save = () => {
    hsl.onUserAction('log file download');
    const filename = `${moment().format('YYYY-MM-DD-HH-mm-s')}_hslog.txt`;
    const type = 'text/plain';
    const data = assembleLogData();

    const file = new Blob([data], {type: type});
    const a = document.createElement('a'),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }

  /**
   * After force check-in, force upload or replace battery commands, this method will refresh the seat data in the modal.
   */
  const refreshSeatData = async () => {
    hsi.handleCmd('get_status').then(async (response: any) => {
      console.debug('SeatDebugModal.tsx refreshSeatData() get_status response', response);
      seatSettings.parseSettings(response);
      cloudApiService.getSeatBySerialNumber(pairContext.serialNumber).then(async (response: any) => {
        seatSettings.parseSettings(response.data);
        console.debug('SeatDebugModal.tsx refreshSeatData() seatSettings context', seatSettings);
      });
    }).catch((e) => {
      console.error(e);
    });
  }

  return (
    <IonModal isOpen={showPanel} backdropDismiss={true} onDidDismiss={removeShowPanel} className="seat-debug-modal">
      <IonContent fullscreen className="seat-debug">
        <IonCard>
          <IonHeader className="seat-debug-header">
            <IonTitle size="large">Seat details and debug log</IonTitle>
            <IonIcon
              onClick={removeShowPanel}
              ios={close}
              md={close}
              className="seat-debug-modal-close-icon"
            >
            </IonIcon>
          </IonHeader>
          <IonItem lines="none">
            <IonGrid className="seat-options">
              <IonRow>
                <IonCol size="12">
                  <IonButton className="btn btn-type-code seat-debug-ctrl-btn" size="large" disabled={buttonsDisabled}
                             onClick={disconnect}>Disconnect Seat</IonButton>
                  <IonButton className="btn btn-type-code" size="large" disabled={buttonsDisabled}
                             onClick={forceCheckIn}>Force Checkin</IonButton>
                  <IonButton className="btn btn-type-code" size="large" disabled={buttonsDisabled}
                             onClick={forceUpload}>Force Upload</IonButton>
                  <IonButton className="btn btn-type-code" size="large" color="danger"
                             disabled={buttonsDisabled} onClick={showReplaceBatteryModal}>Replace Battery</IonButton>
                  <IonButton className="btn btn-type-code" size="large" color="danger"
                             disabled={buttonsDisabled} onClick={reboot}>Reboot</IonButton>
                  {process.env.REACT_APP_DEBUG_MODE === 'true'
                    ? <IonButton className="btn btn-type-code seat-debug-ctrl-btn" size="large" disabled={false}
                               onClick={disconnectDebug}>Disconnect (debug)</IonButton>
                    : null
                  }
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
          <IonItem lines="none">
            <IonGrid className="seat-data">
              <IonRow>
                <IonCol>Serial Number</IonCol>
                <IonCol>{pairContext.serialNumber}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol>Hardware Version</IonCol>
                <IonCol>{seatSettings.settings.hardwareVersion}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol>Firmware Version</IonCol>
                <IonCol>{seatSettings.settings.firmwareVersion}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol>Last Checkin (Local Time)</IonCol>
                <IonCol>{seatSettings.settings.timeOfLastCheckin}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol>Last Recording (Local Time)</IonCol>
                <IonCol>{seatSettings.settings.timeOfLastRecording}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol>Recording Count</IonCol>
                <IonCol>{seatSettings.settings.recordingCount}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol>Battery Voltage</IonCol>
                <IonCol>{seatSettings.settings.batteryVoltage?.toFixed(2)}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol>Percent Charged</IonCol>
                <IonCol>{seatSettings.settings.hardwareVersion !== 'r3' && seatSettings.settings.currentBatteryLevel
                  ? seatSettings.settings.currentBatteryLevel.toFixed(0) + '%'
                  : '-'}
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>Cloud Endpoint</IonCol>
                <IonCol>{seatSettings.settings.cloud_endpoint}</IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
          <IonItem lines="none">
            <IonGrid className="seat-log">
              <IonRow>
                <IonCol size="2" size-md="2">
                  <IonTitle>Log</IonTitle>
                </IonCol>
                <IonCol size="10" size-md="10">
                  <IonButton className="btn btn-type-code" expand="block" onClick={clear}>Clear</IonButton>
                  <IonButton className="btn btn-type-code" expand="block" onClick={save}>Save</IonButton>
                  <IonButton className="btn btn-type-code" expand="block" onClick={copy}>Copy</IonButton>
                </IonCol>
              </IonRow>
              <IonRow id="log-container" className='log-data'>
                {hsl.logData.map((entry: any, i: number) => (
                  <IonText key={i} className={hsl.getLogLineClassName(entry)}>
                    {hsl.getLogLineString(entry)}
                  </IonText>
                ))}
              </IonRow>
            </IonGrid>
          </IonItem>
          <IonFooter>
            <IonRow>
              <IonCol>
                <IonButton className="seat-debug-modal-close-btn" onClick={removeShowPanel}>Close</IonButton>
              </IonCol>
            </IonRow>
          </IonFooter>
        </IonCard>
      </IonContent>
      <ConfirmCancelModal
        isOpen={isSeatDisconnected}
        headerText="Seat has been disconnected"
        subheaderText="Please return to the Pair screen to pair a Heart Seat."
        onButtonAction1={returnToPairAfterDisconnect}
        onButtonAction2={() => {
          setIsSeatDisconnected(false)
        }}
        actionButtonText1="Pair Seat"
        actionButtonText2="Close"
        showWarningIcon={false}
        border
      />
      <ConfirmCancelModal
        isOpen={noSeatSelected}
        headerText="No seat selected. Please return to the pair screen to select a seat."
        onButtonAction1={handleNoSeatSelected}
        actionButtonText1="Ok"
        showWarningIcon={true}
        border
      />
      <ConfirmCancelModal
        isOpen={isReplaceBatterySelected}
        headerText="Replace Batteries"
        subheaderText={replaceBatterySubheaderText}
        footerText={replaceBatteryFooterText}
        onButtonAction1={handleReplaceBattery}
        onButtonAction2={handleCancelReplaceBattery}
        actionButtonText1="RESET BATTERY"
        actionButtonText2="Cancel"
        showWarningIcon={false}
        bigHeader
        border
      />
      <ConfirmCancelModal
        isOpen={isReplaceBatterySuccessful}
        headerText="Seat battery has been reset"
        subheaderText="Current seat battery level: 100%"
        onButtonAction1={handleCloseBatterySuccessful}
        actionButtonText1="Close"
        showWarningIcon={false}
        border
      />
      <ConfirmCancelModal
        isOpen={isReplaceBatteryFailure}
        headerText="Something went wrong..."
        subheaderText="Please try again. If this step fails after repeated attempts, please contact Casana Support."
        onButtonAction1={handleRetryAfterReplaceBatteryFailure}
        onButtonAction2={handleCancelAfterReplaceBatteryFailure}
        actionButtonText1="Retry"
        actionButtonText2="Cancel"
        showWarningIcon={true}
        border
      />
      <ConfirmCancelModal
        isOpen={hasBleConnectionError}
        headerText="Command failed"
        subheaderText="You may need to pair your seat again."
        onButtonAction1={returnToPairAfterCommandError}
        onButtonAction2={() => {
          setHasBleConnectionError(false)
        }}
        actionButtonText1="Pair Seat"
        actionButtonText2="Dismiss"
        showWarningIcon={true}
        border
      />
    </IonModal>
  )
}

export default SeatDebugModal
