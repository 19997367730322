import './Initialize.css';
import React, {useContext, useEffect, useState} from 'react';
import {
  IonContent,
  IonPage,
  IonText,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent, IonRow, IonCol, IonLabel, IonFooter, IonButton
} from '@ionic/react';
import {useHistory} from "react-router-dom";
import {InitializationProvider} from "../../components/Initialize/InitializationProvider";
import useSeatSettings from "../../components/SeatSettings/SeatSettingsHook";
import hsi from "../../lib/HeartSeatInterface";
import {LocationData} from "../../types/LocationData";
import AppLocationContext from "../../components/Includes/AppLocationContext";
import {localStorageRefs} from "../../Refs";
import useInitializationData from "../../components/Initialize/InitializationHook";

interface ContainerProps {
  toggleDebug: Function
}

const InitializeSuccess: React.FC<ContainerProps> = ({toggleDebug}) => {
  const history = useHistory();
  const seatSettings = useSeatSettings();
  const locationContext = useContext<LocationData>(AppLocationContext);
  const init = useInitializationData();

  const [errorText, setErrorText] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [successText, setSuccessText] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);

  useEffect(() => {
    document.title = "Initialize your seat";
  }, []);

  const clearBanner = () => {
    setSuccess(false);
    setError(false);
  }

  const returnToInitForm = () => {
    clearBanner();
    history.push('/initialize/form')
    locationContext.returnView = '/initialize/form';
  }

  /**
   * Clear modals, re-enable auto upload and recording for seat, remove items from local storage, and return to start
   */
  const resetAndRestart = async () => {
    clearBanner();

    try {
      await hsi.handleCmd('checkin', null);
    } catch (e: any) {
      // Do nothing as we are heading back to the pair page anyway.
    }

    localStorage.removeItem(localStorageRefs.patientId);
    localStorage.removeItem(localStorageRefs.seatId);
    toggleDebug(false);
    history.push("/pair");
    locationContext.returnView = '/pair';
    seatSettings.setButtonClass('off');
    hsi.unregisterAllConnectionStatusHandler();
    await hsi.disconnect();
  }

  return (
    <IonPage>
      <InitializationProvider>
        <IonContent fullscreen className="container">
          <IonCard className={error ? 'ion-show flash-message' : 'ion-hide flash-message'}>
            <IonText className="danger">
              {errorText}
            </IonText>
          </IonCard>
          <IonCard className={success ? 'ion-show flash-message' : 'ion-hide flash-message'}>
            <IonText className="success">
              {successText}
            </IonText>
          </IonCard>
          <IonCard className="standard-container">
            <IonCardHeader>
              <IonCardTitle>Success!</IonCardTitle>
              <IonCardSubtitle className="m-t-20 m-b-20">The following initialization was successful. If you notice a mistake
                in the parameters listed below, please select the "Back" button to re-enter the cuff values and re-analyze
                the initialization.
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <IonRow>
                <IonCol size="12" size-md="6">
                  <IonText className="blockquote">
                    <IonCardSubtitle className="bold">Initialization Date:</IonCardSubtitle>

                    <IonCardSubtitle>{new Date(init.data.sitStartTime || '').toLocaleTimeString('en-us', {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true
                    })}</IonCardSubtitle>

                    <IonLabel><IonCardSubtitle className="bold">Initialization Parameters:</IonCardSubtitle></IonLabel>

                    <IonCardSubtitle>BP1: {init.data.bpSystolic1} / {init.data.bpDiastolic1}</IonCardSubtitle>

                    <IonCardSubtitle>BP2: {init.data.bpSystolic2} / {init.data.bpDiastolic2}</IonCardSubtitle>

                    <IonCardSubtitle
                      className="ion-padding-bottom">BP3: {init.data.bpSystolic3} / {init.data.bpDiastolic3}</IonCardSubtitle>

                    <IonCardSubtitle>Sternal notch: {init.data.sternalNotch} in</IonCardSubtitle>

                    <IonCardSubtitle>Height: {init.data.heightFeet} ft {init.data.heightInches} in</IonCardSubtitle>

                    <IonCardSubtitle>Age: {init.data.age} years</IonCardSubtitle>

                    <IonCardSubtitle>Weight: {init.data.weight} lb</IonCardSubtitle>
                  </IonText>
                </IonCol>
              </IonRow>
            </IonCardContent>
            <IonFooter className="standard-container-footer">
              <IonButton className="btn btn-back ion-float-right" onClick={resetAndRestart}>Finish</IonButton>
              <IonButton className="btn btn-next ion-float-right" onClick={returnToInitForm}>Back</IonButton>
            </IonFooter>
          </IonCard>
        </IonContent>
      </InitializationProvider>
    </IonPage>
  );
};

export default InitializeSuccess;

